import React, {useEffect, useState} from 'react';
import './Hashing.scss'
import {Algorithm, ALGORITHMS, BCRYPT} from "./HashingAlgorithms";


function get_algorithm_from_url(): Algorithm | null {
    let url = window.location.hash.substring(1)
    if (url === "") return null
    return ALGORITHMS.find((it) => it.name.toLowerCase() === url.toLowerCase()) || null
}

export const Hashing = () => {
    const [output, setOutput] = useState("");
    const [copied, setCopied] = useState(false);
    const [algorithm, setAlgorithm] = useState(get_algorithm_from_url() || BCRYPT);
    const [input, setInput] = useState("")


    useEffect(() => {
        if (input === "") {
            return setOutput("")
        }
        setOutput("Hashing...")
        console.log("Hashing " + algorithm.name)
        algorithm.hash(input).then((it) => {
            setOutput(it)
            setCopied(false)
        })
    }, [input, algorithm]);

    function select(algorithm: Algorithm) {
        const url = new URL(window.location.toString());
        url.hash = "#" + algorithm.name.toLowerCase()
        window.history.replaceState({}, "", url);

        setAlgorithm(algorithm)
    }

    let warning = null
    if (algorithm === BCRYPT && input.length > 72) {
        warning = <div className="alert-warning">BCrypt only allows for passwords up to 72 chars</div>
    }

    return (
        <div>
            <div className="input-group">
                <div className="container">
                    <span className="label">Service</span>
                    <span className="input">
                        <input type="button" value="FTP" onClick={() => select(BCRYPT)}/>
                        <input type="button" value="E-Mail" onClick={() => select(BCRYPT)}/>
                    </span>
                </div>
            </div>
            <div className="input-group">
                <div className="container">
                    <span className="label">Algorithm</span>
                    <span className="input">
                {
                    ALGORITHMS.map((it) => (
                        <label key={it.name} className="radio">
                            <input type="radio" name="algorithm" checked={algorithm === it} onSelect={() => select(it)} onChange={() => select(it)}/>
                            <span className="radio"></span>
                            {it.name}
                        </label>))
                }
                    </span>
                </div>
            </div>

            <div className="input-group">
                <label>
                    <span className="label">Input (password)</span>
                    <span className="input">
                        <input className="hashing-input-field" type="password" value={input} onChange={(it) => setInput(it.target.value)}/>
                        <small>Note: All hashes are calculated in your browser and are not sent to any server.</small>
                    </span>
                </label>
            </div>

            {warning}
            
            <div>
                <h2>Output</h2>
                <textarea className="hashing-output-field" value={output} onChange={it => setOutput(it.target.value)} readOnly={true}/>
                <input type="button" className="copy-to-clipboard" disabled={copied || output === ""} value={copied ? "Copied!" : "Copy to clipboard"} onClick={() => {
                    navigator.clipboard.writeText(output);
                    setCopied(true)
                }}/>
            </div>
        </div>
    );
}
