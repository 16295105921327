import React, {useState} from 'react';
import {getRandomInt} from "../../../util/TUtil";
import './RandomChooser.scss'


function splitAt(input: string, separators: Set<string>): string[] {
    const output = new Set();

    let current = ""
    for (let i = 0; i < input.length; i++) {
        const char = input.charAt(i)
        if (separators.has(char)) {
            const trimmed = current.trim()
            current = ""
            if (trimmed !== "") output.add(trimmed)
            continue
        }

        current = current + char
    }
    const trimmed = current.trim()

    if (trimmed !== "") {
        output.add(trimmed)
    }


    // @ts-ignore
    return Array.from(output)
}

export const RandomChooser = () => {
    const [input, setInput] = useState("");
    const [separators, setSeparators] = useState("\\n");
    const [output, setOutput] = useState("");

    function choose() {
        let filteredSeparators = separators
            .replace('\\n', '\n')
            .split("")

        let possibilities = splitAt(input, new Set(filteredSeparators))

        let option = possibilities[getRandomInt(0, possibilities.length)]

        setOutput(option)
    }


    return (
        <div>
            <div className="input-group">
                <label>
                    <span className="label">Options</span>
                    <span className="input">
                        <textarea className="random-options" value={input} onChange={(e) => setInput(e.target.value)}></textarea>
                        <small>Note: The option is chosen in your browser and not sent to any server.</small>
                    </span>
                </label>
            </div>
            <div className="input-group">
                <label>
                    <span className="label">Separators</span>
                    <span className="input">
                        <input value={separators} onChange={(e) => setSeparators(e.target.value)} type="text"/>
                        <small>You can write a <code>\n</code> for a new line</small>
                    </span>
                </label>
            </div>
            <div className="input-group">
                <button className="button-warning" onClick={() => choose()}>Choose one from the list</button>
            </div>

            <div className="input-group">
                <label>
                    <span className="label">Output</span>
                    <span className="input">
                        <textarea value={output} readOnly></textarea>
                    </span>
                </label>
            </div>
        </div>
    );
}
