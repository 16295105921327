import './Tool.scss';
import React from "react";
import {Tool} from "./Tool";

export function NoTool() {
    document.title = "Tools"
    return (<div className="box-ng">
        <h1 className="header header-accent">Tools</h1>
        <div className="box-body">
            <p className="wide-text text-center">No tool selected! Please select a tool above</p>
            <img className="notool-logo" src="/logo512.png" alt=""></img>
        </div>
    </div>)
}

export function renderTool(tool: Tool) {
    document.title = tool.name + " - Tools" // tools.bixilon.de?
    return (<div className="box-ng">
        <h1 className="header header-accent">{tool.name}</h1>
        <div className="box-body">
            <p className="wide-text">{tool.description}</p>
            <div className="tool-content">{tool.render()}</div>
        </div>
    </div>)
}
