import {Tool} from "../Tool";
import {Hashing} from "./Hashing";


export class HashingTool implements Tool {
    name = "Hashing"
    url = "hashing"
    description = "This tool \"encrypts\" your password in a way that it is impossible to decrypt it but still verifiable. That prevents (think about a database leak) the usage of that password at other services."

    render = Hashing
}
