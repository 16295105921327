import React, {useState} from 'react';

import './Length.scss'


export const Length = () => {
    const [input, setInput] = useState("");

    return (
        <div>
            <div className="counter-wrapper">
                <div className="counter">{input.length}</div>
            </div>
            <div className="input-group">
                <label>
                    <span className="label">Input</span>
                    <span className="input">
                        <textarea key="input" className="string-length-input" value={input} onChange={e => setInput(e.target.value)}/>
                        <small>Note: The length of the string is counted in your browser and not sent to any server.</small>
                    </span>
                </label>
            </div>
        </div>
    );
}
