import React, {useEffect, useState} from 'react';
import {getNumberFromURL, getRandomInt, updateURLParameters} from "../../../util/TUtil";

const MIN = 1
const MAX = 6

const MIN_PARAMETER = 'min'
const MAX_PARAMETER = 'max'

export const RandomNumber = () => {
    const [min, setMin] = useState(getNumberFromURL(MIN_PARAMETER, MIN));
    const [max, setMax] = useState(getNumberFromURL(MAX_PARAMETER, MAX));
    const [display, setDisplay] = useState("?");

    function roll() {
        let first = min
        let second = max
        if (max < min) {
            first = max
            second = min
            setMin(max)
            setMax(min)
        }
        const number = getRandomInt(first, second + 1)
        setDisplay("" + number)
    }

    useEffect(() => updateURLParameters(MIN_PARAMETER, min, MIN), [min])
    useEffect(() => updateURLParameters(MAX_PARAMETER, max, MAX), [max])

    return (
        <div>
            <div className="counter-wrapper">
                <div className="counter">{display}</div>
            </div>

            <div className="input-group">
                <label>
                    <span className="label">Minimum</span>
                    <span className="input">
                        <input value={min} onChange={(e) => setMin(parseInt(e.target.value))} type="number"/>
                        <small>(inclusive)</small>
                    </span>
                </label>
            </div>
            <div className="input-group">
                <label>
                    <span className="label">Maximum</span>
                    <span className="input">
                        <input value={max} onChange={(e) => setMax(parseInt(e.target.value))} type="number"/>
                        <small>(inclusive)</small>
                    </span>
                </label>
            </div>

            <div className="input-group">
                <button className="button-warning" onClick={() => roll()}>Roll the dice</button>
            </div>
        </div>
    );
}
