import {getRandomInt} from "../../util/TUtil";

const UPPERCASE_CHARS = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
const LOWERCASE_CHARS = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
const NUMBER_CHARS = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
const VOWEL_UPPERCASE_CHARS = ['Ä', 'Ö', 'Ü']
const VOWEL_LOWERCASE_CHARS = ['ä', 'ö', 'ü']

const SPECIAL_CHARS = ['!', '=', '?', '(', ')', '[', ']', '+', '#', '*', '~', '.', ',', '-', '_', ':', ';', '=']
const PROBLEMATIC_CHARS = ['%', '"', '\'', '$', '', '/', '§', '{', '}', 'ß', '&', '\\', '`', '<', '>', '°', '^']

const CONFUSABLES = [
    '1', 'l', 'I',
    'o', 'O', '0',
]

export class Settings {
    uppercase = true
    lowercase = true
    numbers = true
    vowel = false
    special = true
    problematic = false
    confusables = false

    includes = ""
    excludes = ""
}


export const DEFAULT_SETTINGS = new Settings()

export function getAlphabet(settings: Settings) {
    let chars: Set<string> = new Set();
    if (settings.uppercase) {
        UPPERCASE_CHARS.forEach(chars.add, chars)

        if (settings.vowel) VOWEL_UPPERCASE_CHARS.forEach(chars.add, chars)
    }
    if (settings.lowercase) {
        LOWERCASE_CHARS.forEach(chars.add, chars)

        if (settings.vowel) VOWEL_LOWERCASE_CHARS.forEach(chars.add, chars)
    }

    if (settings.numbers) NUMBER_CHARS.forEach(chars.add, chars)
    if (settings.special) SPECIAL_CHARS.forEach(chars.add, chars)
    if (settings.problematic) PROBLEMATIC_CHARS.forEach(chars.add, chars)

    if (!settings.confusables) {
        CONFUSABLES.forEach(chars.delete, chars)
    }

    settings.includes.split("").forEach(chars.add, chars)
    settings.excludes.split("").forEach(chars.delete, chars)


    return Array.from(chars)
}


function getRandomChar(alphabet: string[]) {
    return alphabet[getRandomInt(0, alphabet.length)];
}

export function generatePassword(alphabet: string[], length: number) {
    if (alphabet.length <= 1) throw new Error("Alphabet is empty!")
    if (length < 0) throw new Error("Invalid length!")

    let password = ""

    for (let i = 0; i < length; i++) {
        password = password + getRandomChar(alphabet)
    }

    return password
}
