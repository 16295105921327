import React, {useEffect, useState} from 'react';


export const StringUtil = () => {
    const [data, setData] = useState('');
    const [copied, setCopied] = useState(false);


    useEffect(() => {
        setCopied(false)
    }, [data]);

    return (
        <div>
            <div className="input-group">
                <label>
                    <span className="label">Data</span>
                    <span className="input">
                        <textarea className="random-options" value={data} onChange={(e) => setData(e.target.value)}></textarea>
                        <input type="button" className="copy-to-clipboard" disabled={copied || data === ""} value={copied ? "Copied!" : "Copy to clipboard"} onClick={() => {
                            navigator.clipboard.writeText(data);
                            setCopied(true)
                        }}/>
                        <small>Note: This input/output data is processed in your browser and not sent to any server.</small>
                    </span>
                </label>
            </div>

            <div className="input-group">
                <div className="container">
                    <span className="label">Actions</span>
                    <span className="input">
                        <button className="button-error" onClick={() => setData('')}>Clear</button>
                    </span>
                </div>
            </div>

            <div className="input-group">
                <div className="container">
                    <span className="label">Casing</span>
                    <span className="input">
                        <button className="button-warning" onClick={() => setData(data.toUpperCase())}>Uppercase</button>
                        <button className="button-warning" onClick={() => setData(data.toLowerCase())}>Lowercase</button>
                    </span>
                </div>
            </div>
        </div>
    );
}
