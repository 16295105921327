import React from 'react';


export const Others = () => {
    return (
        <div>
            <ul>
                <li>Tool for data conversion (encoding, decoding, compressing, converting, minifying, ...): <a href="https://gchq.github.io/CyberChef/">CyberChef</a></li>
                <li>All information about colors: <a href="https://convertingcolors.com/">Converting Colors</a></li>
                <li>Regular expression testing: <a href="https://regex101.com/">regex101</a></li>
                <li>Floats in binary: <a href="https://www.h-schmidt.net/FloatConverter/IEEE754.html">IEEE-754 Floating Point Converter</a></li>
            </ul>
        </div>
    );
}
