import {Tool} from "../Tool";
import {Password} from "./Password";


export class PasswordTool implements Tool {
    name = "Password Generation"
    url = "password"
    description = "This tool generates a random password for you"

    render = Password
}
