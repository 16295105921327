import React from 'react';
import {Link, Navigate, Route, Routes, useLocation} from "react-router-dom";

import {NoTool, renderTool} from '../tools/ToolRenderer'
import {ToolManager} from "../tools/Tool";

import './App.scss';


function App() {
    const location = useLocation();

    return (<div>
        <div className="box-top">
            <div className="navbar">
                <Link to="/"><img alt="logo" src="/logo512.png"/></Link>
                <div className="navbar-links">
                    {
                        ToolManager.tools.map((it) => (<Link key={it.name} className={location.pathname === "/" + it.url ? "navbar-active" : ""} to={"/" + it.url}>{it.name}</Link>))
                    }
                    <div className="navbar-spacer"></div>
                    <a className="logo" href="https://bixilon.de">bixilon.de
                        <div className="navbar-spacer"></div>
                        <img alt="logo" src="/bixilon.svg"/></a>
                </div>
            </div>
        </div>
        <div className="content">
            <Routes>
                {
                    ToolManager.tools.map((it) => (<Route key={it.name} path={"/" + it.url} Component={() => renderTool(it)}/>))
                }
                <Route index path="/" Component={() => <NoTool/>}/>
                <Route path="*" element={<Navigate to="/"/>}/>
            </Routes>
        </div>
    </div>);
}

export default App;
