import {Tool} from "../Tool";
import {StringUtil} from "./StringUtil";


export class StringTool implements Tool {
    name = "String util"
    url = "string"
    description = "Perform operations on a string"

    render = StringUtil
}
