import {Tool} from "../Tool";
import {Others} from "./Others";


export class OthersTool implements Tool {
    name = "Other tools"
    url = "others"
    description = "There are some really great tools that I did not develop, but I sometimes need their functionality. Instead of implementing (a probably wrong or really light version of it), I am really happy that I can link to them. Note: I do not take any responsibility over those tools."

    render = Others
}
