import {HashingTool} from "./hashing/HashingTool";
import {PasswordTool} from "./password/PasswordTool";
import {LengthTool} from "./length/LengthTool";
import {OthersTool} from "./others/OthersTool";
import {RandomChooserTool} from "./random/chooser/RandomChooserTool";
import {RandomNumberTool} from "./random/number/RandomNumberTool";
import {StringTool} from "./string/StringTool";
import {JSX} from "react";

export interface Tool {
    name: string
    url: string
    description: string

    render: () => JSX.Element
}

export class ToolManager {
    public static tools: Tool[] = [
        new HashingTool(),
        new PasswordTool(),
        new LengthTool(),
        new StringTool(),

        // TODO: make "random" tools appear in a dropdown and group them together
        new RandomChooserTool(),
        new RandomNumberTool(),

        new OthersTool(),
    ]
}
