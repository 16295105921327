import {Tool} from "../Tool";
import {Length} from "./Length";


export class LengthTool implements Tool {
    name = "String Length"
    url = "length"
    description = "Paste a string in here, to get the length of it"

    render = Length
}
