import {Tool} from "../../Tool";
import {RandomChooser} from "./RandomChooser";


export class RandomChooserTool implements Tool {
    name = "Random chooser"
    url = "random/chooser"
    description = "Choose one option randomly of many"

    render = RandomChooser
}
