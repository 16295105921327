import React, {useEffect, useState} from 'react';
import './Password.scss'
import {DEFAULT_SETTINGS, generatePassword, getAlphabet, Settings} from "./PasswordUtil";
import {updateURLParameters} from "../../util/TUtil";

class Mutation {
    name: string
    description: any
    defaultValue: boolean

    constructor(name: string, description: any) {
        this.name = name
        this.description = description
        // @ts-ignore
        this.defaultValue = DEFAULT_SETTINGS[name]
    }
}

const UPPERCASE = new Mutation("uppercase", <span>Uppercase (e.g. <code>ABCDEF</code>)</span>)
const LOWERCASE = new Mutation("lowercase", <span>Lowercase (e.g. <code>abcdef</code>)</span>)
const NUMBERS = new Mutation("numbers", <span>Numbers (e.g. <code>01234</code>)</span>)
const VOWEL = new Mutation("vowel", <span>Vowel (e.g. <code>ÄäÖöÜü</code>)</span>)
const SPECIAL = new Mutation("special", <span>Special chars (e.g. <code>!=?)(+#*~</code>)</span>)
const PROBLEMATIC = new Mutation("problematic", <span>Problematic special chars (e.g. <code>\"$/§</code>)</span>)
const CONFUSABLES = new Mutation("confusables", <span>Confusables (e.g. <code>1lI</code>, <code>o0O</code>)</span>)

const MUTATIONS = [UPPERCASE, LOWERCASE, NUMBERS, VOWEL, SPECIAL, PROBLEMATIC, CONFUSABLES]


const DEFAULT_LENGTH = 20

type Props = {
    mutation: Mutation
    settings: Settings;
    hook: () => void;
};

function getURLValue(name: string, defaultValue: boolean) {
    const url = new URL(window.location.toString()).searchParams.get(name)
    if (url === "true") return true
    if (url === "false") return false

    return defaultValue
}


const MutationComponent = ({mutation, settings, hook}: Props) => {
    const defaultValue = getURLValue(mutation.name, mutation.defaultValue)
    const [enabled, setEnabled] = useState(defaultValue);

    // @ts-ignore
    settings[mutation.name] = defaultValue

    function update(value: boolean) {
        updateURLParameters(mutation.name, value, mutation.defaultValue)

        // @ts-ignore
        settings[mutation.name] = value
        setEnabled(value)
        hook()
    }

    return <label className="checkbox">
        <input type="checkbox" name={mutation.name} checked={enabled} onChange={e => update(e.target.checked)}/>
        <span className="checkbox"></span>
        {mutation.description}
    </label>
}


const settings = new Settings()
export const Password = () => {
    const [length, setLength] = useState(DEFAULT_LENGTH);

    const [output, setOutput] = useState(generate());
    const [copied, setCopied] = useState(false);


    const [includes, setIncludes] = useState(new URL(window.location.toString()).searchParams.get('includes') || '');
    const [excludes, setExcludes] = useState(new URL(window.location.toString()).searchParams.get('excludes') || '');

    function generate(): string {
        const alphabet = getAlphabet(settings)
        if (alphabet.length <= 1) {
            return "Alphabet is empty!"
        }
        return generatePassword(alphabet, length)
    }

    function update() {
        setOutput(generate())
        setCopied(false)
    }

    useEffect(() => {
        updateURLParameters('length', length, DEFAULT_LENGTH)
        update()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [length])

    useEffect(() => {
        updateURLParameters('includes', includes, "")
        settings.includes = includes
        update()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [includes])
    useEffect(() => {
        updateURLParameters('excludes', excludes, "")
        settings.excludes = excludes
        update()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [excludes])


    return (
        <div>
            <div className="input-group">
                <label>
                    <span className="label">Length</span>
                    <span className="slider">
                    <input type="range" min="1" max="100" value={length} onChange={e => setLength(+e.target.value)} className="slider" id="length"/>
                    <span>{length}</span>
                    </span>
                </label>
            </div>
            <div className="input-group">
                <div className="container">
                    <span className="label">Mutations</span>
                    <span className="input">
                    {
                        MUTATIONS.map((it) => (
                            <MutationComponent key={it.name} mutation={it} settings={settings} hook={() => update()}></MutationComponent>
                        ))
                    }
                        </span>
                </div>
            </div>

            <div className="input-group">
                <label>
                    <span className="label">Includes</span>
                    <input className="input" value={includes} onChange={(e) => setIncludes(e.target.value)} type="text"/>
                </label>
            </div>
            <div className="input-group">
                <label>
                    <span className="label">Excludes</span>
                    <input className="input" value={excludes} onChange={(e) => setExcludes(e.target.value)} type="text"/>
                </label>
            </div>

            <div className="input-group">
                <button className="button-warning" onClick={() => update()}>Generate password</button>
                <small>Note: All passwords are generated in your browser and are not sent to any server. They use <a href="https://developer.mozilla.org/en-US/docs/Web/API/Crypto/getRandomValues">crypto.getRandomValues()</a> to generate a cryptographic secure number.</small>
            </div>

            <h2>Generated password</h2>

            <textarea value={output} onChange={e => setOutput(e.target.value)} readOnly={true}/>
            <input type="button" className="copy-to-clipboard" disabled={copied || output === ""} value={copied ? "Copied!" : "Copy to clipboard"} onClick={() => {
                navigator.clipboard.writeText(output);
                setCopied(true)
            }}/>
        </div>
    );
}
