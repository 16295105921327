import {Tool} from "../../Tool";
import {RandomNumber} from "./RandomNumber";


export class RandomNumberTool implements Tool {
    name = "Random number"
    url = "random/number"
    description = "Roll a dice of custom size"

    render = RandomNumber
}
