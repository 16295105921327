import bcrypt from "bcryptjs";
import {sha1, sha256, sha384, sha512} from "crypto-hash";

export abstract class Algorithm {
    abstract name: string

    public abstract hash(input: string): Promise<string>
}

class CryptoAlgorithm extends Algorithm {
    name: string
    hash_function: { (input: (string)): Promise<string> }

    constructor(name: string, hash_function: { (input: (string)): Promise<string> }) {
        super();
        this.name = name
        this.hash_function = hash_function
    }

    async hash(input: string) {
        return this.hash_function(input)
    }
}

export const BCRYPT = new class extends Algorithm {
    name = "BCRYPT"

    async hash(input: string) {
        return bcrypt.hash(input, 10)
    }
}();

export const SHA1 = new CryptoAlgorithm("SHA1", sha1)
export const SHA256 = new CryptoAlgorithm("SHA256", sha256)
export const SHA384 = new CryptoAlgorithm("SHA384", sha384)
export const SHA512 = new CryptoAlgorithm("SHA512", sha512)


export const ALGORITHMS: Algorithm[] = [BCRYPT, SHA1, SHA256, SHA384, SHA512]
