export function getRandomInt(min: number = 0, max: number): number {
    const buffer = new Int32Array(1);
    crypto.getRandomValues(buffer)

    let number = (buffer[0] & 0x7FFFFFFF) % (max - min)

    return number + min
}

export function updateURLParameters(name: string, value: any, defaultValue: any) {
    const url = new URL(window.location.toString());
    if (value === defaultValue) {
        url.searchParams.delete(name)
    } else {
        url.searchParams.set(name, "" + value);
    }

    window.history.replaceState({}, "", url);
}

export function getParameterFromURL(name: string, defaultValue: any): any {
    const url = new URL(window.location.toString());
    return url.searchParams.get(name) || defaultValue
}

export function getNumberFromURL(name: string, defaultValue: number): number {
    const value = getParameterFromURL(name, defaultValue)

    try {
        return parseInt(value)
    } catch (error: any) {
        return defaultValue
    }
}
